import { BeRouteContainer } from '@containers';
import { getSSP } from '@dxp-next-server';
import type { NextCatchAllRoute } from '@dxp-next-server';
import Layout from '@layouts/main';

import { countryCode, businessUnit, label, name } from '../config';
import components from '../sitecoreComponents';

const CatchAllRoute: NextCatchAllRoute = ({ dataLayerEvents, i18n, idToken, layoutData, path, platformHint, user }) => (
  <BeRouteContainer
    businessUnit={businessUnit}
    components={components}
    dataLayerEvents={dataLayerEvents}
    i18n={i18n}
    idToken={idToken}
    label={label}
    layoutData={layoutData}
    name={name}
    path={path}
    platformHint={platformHint}
    user={user}>
      <Layout />
  </BeRouteContainer>
);

export default CatchAllRoute;

export const getServerSideProps = getSSP({ businessUnit, countryCode, label });
