import { FC } from 'react';

import DigitalCoreBeProvider from '@dc-be/provider';

import { SitecoreRouteContainer, SitecoreRouteProps } from './Route';

const BeRouteContainer:FC<SitecoreRouteProps> = ({children, ...props}) => {
  return <SitecoreRouteContainer {...props}>
      <DigitalCoreBeProvider>
        {children}
      </DigitalCoreBeProvider>
    </SitecoreRouteContainer>
};

export { BeRouteContainer }