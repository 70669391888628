import { ReactNode } from "react"

import env from '@common/env';

import { client } from "../client/services.gen"

const DCBE_HOST = env('DCBE_HOST');

if(DCBE_HOST) {
  client.setConfig({
    'baseUrl': DCBE_HOST,
  })
}


export default function DigitalCoreBeProvider({children}:{children: ReactNode}) {
  return children
}